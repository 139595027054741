@import '_variables';

.page_container {
  margin-left     : 50px;
  min-height      : 100vh;
  background-color: $background_page;

  .page_wrapper {
    padding: 74px 20px 20px;

    &.with_sidebar {
      display: flex;
    }

    &.max_width {
      max-width: 1440px;
      margin   : 0 auto;
    }

    .page_main {
      width         : 100%;
      display       : flex;
      flex-direction: column;
      gap           : 20px;

      &.fixed_sidebar {
        margin-left: 340px;
      }
    }
  }
}

.home_page_container {
  display        : flex;
  align-items    : center;
  justify-content: center;
  height         : 200px;

  .home_page_button_container {
    display        : flex;
    align-items    : center;
    justify-content: space-between;
    gap            : 10px;

    .login_button,
    .logout_button {
      width              : 135px;
      color              : $white;
      @include font($size: 16px, $weight: 700);
      box-shadow         : none;
    }

    .admin_button {
      display            : inline-block;
      padding            : 6px 16px;
      color              : $white;
      @include font($size: 16px, $weight: 700);
      background-color   : $main;
      text-decoration    : none;
      border-radius      : 4px;
    }
  }
}
