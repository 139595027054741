@import 'scss/_variables';

.subpage_tabs_container {
  position        : fixed;
  top             : 0;
  left            : 40px;
  right           : 0;
  display         : flex;
  align-items     : center;
  justify-content : space-between;
  padding-left    : 20px;
  background-color: $white;
  box-shadow      : 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
  z-index         : 10;

  .subpage_tabs_wrapper {
    display    : flex;
    align-items: center;

    .subpage_tab_link {
      height     : 50px;
      display    : flex;
      align-items: center;
      padding    : 20px;
      @include font;
      text-decoration: none;
      color          : $text_dark;
      position       : relative;

      &:hover {
        color: $main;
      }

      &.active {
        color: $main;

        &::before {
          content         : '';
          position        : absolute;
          bottom          : 0;
          left            : 0;
          right           : 0;
          height          : 3px;
          width           : 100%;
          background-color: $main;
        }
      }
    }
  }
}
