@import 'scss/_variables';

.drag_slider_container.MuiSlider-root {
  color: $main;

  .MuiSlider-rail {
    background-color: $grey_light;
    opacity         : 1;
  }

  .MuiSlider-mark {
    background-color: $white;
    border          : 2px solid $grey_light;
    width           : 10px;
    height          : 10px;
    border-radius   : 50%;
    transform       : translate(-50%, -50%);

    &.MuiSlider-markActive {
      background-color: $main;
      border          : 2px solid $main;
    }
  }

  .MuiSlider-markLabel {
    @include font();
    color: $grey_light;
  }

}
