@import 'scss/variables';

.breadcrumbs_container {
  .breadcrumbs_active_link {
    text-decoration      : none;
    color                : $main;
    @include font($weight: 700);
  }

  .breadcrums_current_link {
    color: $background_active;
    @include font();
  }
}
