@import 'scss/_variables';

.source_table_wrapper {
  display       : flex;
  flex-direction: column;
  min-width     : 50%;

  .source_table_container.MuiTableContainer-root {
    box-shadow: none;

    .source_table {

      th.MuiTableCell-root.MuiTableCell-head,
      th.MuiTableCell-root.MuiTableCell-body,
      td.MuiTableCell-root.MuiTableCell-body {
        @include font;
        border: 1px solid $grey_border;
      }

      .source_table_number_column {
        width: 5%;
      }
    }
  }

  .source_table_tools_container {
    display        : flex;
    justify-content: space-between;
    align-items    : center;

    .source_table_download_data {
      background-color: $white;
      border          : 1px solid $main;
      border-radius   : 5px;
    }
  }
}
