@import 'scss/_variables';

.snp_details_container {
  padding         : 10px;
  background-color: $white;
  @include font();

  .snp_details_row {
    display              : grid;
    grid-template-columns: 2fr 5fr 5fr;
  }

  .snp_detail_link_container {
    margin-top: 10px;

    .snp_detail_link {
      text-decoration: none;
      color          : $main;

      &:hover {
        color: $main_dark;
      }
    }
  }
}
