@import 'scss/_variables';

.admin_top_menu_container {
  position        : fixed;
  top             : 0;
  right           : 0;
  left            : 40px;
  background-color: $white;
  height          : 50px;
  display         : flex;
  justify-content : space-between;
  align-items     : center;
  padding         : 0 20px;

  .admin_top_menu_controls {
    display    : flex;
    align-items: center;

    .admin_top_menu_account {
      margin-right: 5px;
    }

    .admin_top_menu_settings {
      margin-left: 20px;
    }
  }
}
