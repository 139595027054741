@import 'scss/_variables';

.user_table_container.MuiTableContainer-root {
  padding   : 20px;
  box-shadow: none;

  .user_table {

    th.MuiTableCell-root.MuiTableCell-head,
    th.MuiTableCell-root.MuiTableCell-body,
    td.MuiTableCell-root.MuiTableCell-body {
      @include font;
    }

    th.MuiTableCell-root.MuiTableCell-head {
      color: $grey_light;
    }

    .user_table_row.MuiTableRow-root {

      &:hover {
        background-color: $main_light;

        td.user_table_action_cell {

          .user_table_delete {
            opacity   : 1;
            visibility: visible;
          }
        }
      }

      td.MuiTableCell-root.MuiTableCell-body:first-child {
        font-weight: 700;
      }

      td.user_table_action_cell {
        vertical-align: middle;

        .user_table_edit,
        .user_table_delete {
          @include font;
          opacity   : 0;
          visibility: hidden;
        }
      }
    }
  }
}
