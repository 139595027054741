@import 'scss/_variables';

.navbar_container {
  background-color: $background;
  position        : fixed;
  top             : 0;
  left            : 0;
  bottom          : 0;
  width           : 50px;
  transition      : width 0.2s linear;
  overflow        : hidden;
  z-index         : 1000;

  &:hover {
    width: 160px;

    .navbar_logo_container {
      .navbar_logo_link {
        line-height: 0;

        .navbar_logo {
          margin-left: 0;

          .logo_text {
            fill: $white;
          }
        }
      }
    }

    .navbar_link_container {
      .navbar_link {
        color: $text_light;
      }
    }
  }

  .hidden {
    color           : black;
    width           : 50px;
    height          : 50px;
    background-color: red;
    display         : none;
    position        : fixed;
    left            : 100%;
    top             : 0;
  }

  .navbar_logo_container {
    display        : flex;
    align-items    : center;
    justify-content: center;

    .navbar_logo_link {
      line-height: 0;

      .navbar_logo {
        margin-left: 68px;
        transition : all 0.2s linear;

        .logo_text {
          transition: fill 0.2s linear;
          fill      : transparent
        }
      }
    }
  }

  .navbar_link_container {
    width        : 160px;
    height       : 50px;
    border-bottom: 1px solid $grey_dark;
    position     : relative;

    &:hover {
      .hidden {
        display: block;
      }
    }

    .navbar_link {
      padding-left   : 13px;
      width          : 100%;
      height         : 100%;
      display        : flex;
      gap            : 10px;
      align-items    : center;
      text-decoration: none;
      color          : transparent;
      transition     : color 0.2s linear;
      @include font();

      &.active {
        background-color: $background_active;
      }

      .navbar_icon {
        height: 24px;
        width : 24px;
      }
    }
  }
}
