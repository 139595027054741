@import 'scss/_variables';

.target_snp_table_section_container {
  display       : flex;
  flex-direction: column;
  gap           : 20px;

  .target_snp_category {
    padding         : 10px;
    background-color: $white;

    .target_snp_source_container {
      display       : flex;
      flex-direction: column;
      gap           : 10px;


      .target_snp_description_container {
        @include font();

        .target_snp_description {
          margin-bottom: 10px;

          .target_snp_link {
            text-decoration: none;
            color          : $main;

            &:hover {
              color: $main_dark;
            }
          }
        }
      }
    }

  }

  .target_snp_table_container {
    display       : flex;
    flex-direction: column;
  }
}
