@import 'scss/_variables';

.plot_data_container {
  @include panel;
  flex           : 1;
  display        : flex;
  justify-content: space-between;
  flex-direction : column;

  .plot_data_wrapper {
    flex           : 1;
    display        : flex;
    justify-content: space-between;

    .plot_data_side_wrapper {
      display       : flex;
      flex-direction: column;
      gap           : 15px;
      width         : 350px;
      padding       : 20px;

      .plot_data_download_all {
        @include font($weight: 700);
      }

      .plot_data_qudrant_actions {
        display              : grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows   : 1fr 1fr;
        gap                  : 10px;


        .top_right,
        .top_left,
        .bottom_left,
        .bottom_right {
          width: 100%;
        }

        .top_right {
          .MuiButton-endIcon {
            transform: rotate(90deg);
          }
        }

        .bottom_right {
          .MuiButton-endIcon {
            transform: rotate(180deg);
          }
        }

        .bottom_left {
          .MuiButton-endIcon {
            transform: rotate(-90deg);
          }
        }

      }

      .plot_data_details {
        display       : flex;
        align-items   : center;
        flex-direction: column;
        color         : $text_dark;

        .plot_data_details_row {
          display        : flex;
          align-items    : center;
          justify-content: space-between;
          width          : 100%;
          gap            : 10px;

          .plot_data_details_key {
            display: inline-block;
            @include font;
          }

          .plot_data_details_value {
            display              : inline-block;
            @include font($weight: 700)
          }
        }
      }

      .plot_data_save_gene_list {
        @include font($weight: 700);
      }

      .axis_params_container {
        display        : flex;
        align-items    : center;
        justify-content: space-around;
        flex-direction : column;
        border-radius  : 10px;
        gap            : 10px;

        .axis_params_row {
          display: flex;
          gap    : 5px;
        }
      }

      .four_way_params {
        .four_way_params_clear_button {
          width: 100%;
        }
      }

    }
  }

}
