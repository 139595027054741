@import 'scss/_variables';

.slider_panel_container {
  width  : 100%;
  padding: 10px;

  .slider_info {
    display        : flex;
    align-items    : center;
    justify-content: space-between;

    .slider_panel_input {
      outline               : none;
      text-align            : right;
      padding               : 0 5px;
      width                 : 50px;
      border                : none;
      border-radius         : 5px;
      @include font($weight : 700);

      &[type='number'] {
        -moz-appearance: textfield;
      }

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }
    }
  }
}
