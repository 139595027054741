@import 'scss/_variables';

.dropdown_container.MuiFormControl-root {
  border-radius: 5px;

  &:hover,
  &:active {
    outline: 1px solid $main;
  }

  .MuiFormLabel-root {
    @include font();
    color: $text_light;
  }

  .MuiAutocomplete-inputRoot {
    padding-top   : 25px;
    padding-bottom: 5px;

    .MuiChip-root {
      background-color: $background_page;
      color           : $main;
      border          : 1px solid $main;

      svg {
        color: $main;
      }
    }
  }


  .MuiInputBase-root {
    @include font($weight: 700);
    color                : $text_dark;
    box-sizing           : border-box;
    border-radius        : 5px;
    background-color     : $background_page;

    &.Mui-error {
      border: 1px solid $error;
    }


    &::before {
      border-bottom: none;
    }

    &::after {
      display: none;
    }

    &:hover {
      &::before {
        border-bottom: none;
      }
    }

    &:focus {
      background-color: $background_page;
    }

    .MuiSelect-select {
      text-overflow: ellipsis;
      overflow     : hidden;
      white-space  : normal;

      &:focus {
        background-color: $background_page;
        outline         : 1px solid $main;
        border-radius   : 5px;

      }
    }
  }
}
