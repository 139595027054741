@import 'scss/_variables';

.volcano_gene_table_container {
  width           : 100%;
  height          : 600px;
  padding         : 15px;
  background-color: $white;

  .volcano_gene_table_table_container.MuiTableContainer-root {
    box-shadow: none;

    .volcano_gene_table {
      border-top: 1px solid $grey_border;

      th.MuiTableCell-root.MuiTableCell-head,
      th.MuiTableCell-root.MuiTableCell-body,
      td.MuiTableCell-root.MuiTableCell-body {
        border: 1px solid $grey_border;
        @include font()
      }
    }
  }
}
