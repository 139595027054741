@import 'scss/_variables';

.gene_list_button {
  width           : 50px;
  height          : 50px;
  border          : none;
  display         : flex;
  align-items     : center;
  justify-content : center;
  background-color: $main_light;
  cursor          : pointer;

  &:focus-visible {
    outline: none;
  }
}
