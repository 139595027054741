@import 'scss/_variables';

.gene_list_container {
  display       : flex;
  flex-direction: column;
  height        : 100%;

  .gene_list_title {
    display      : flex;
    align-items  : center;
    padding-left : 20px;
    min-height   : 50px;
    border-bottom: 1px solid $grey_border;
  }

  .gene_list_content {
    display       : flex;
    flex-direction: row;
    height        : 100%;
    overflow      : hidden;

    .gene_list_lists {
      display       : flex;
      flex-direction: column;
      flex-shrink   : 1;
      border-right  : 1px solid $grey_border;

      .gene_list_lists_search_container {
        padding: 20px;
      }

      .gene_list_names {
        overflow-y: scroll;
        @include scrollbar;

        .gene_list_name {
          @include font();
          padding-left : 25px;
          padding      : 13px 0 13px 20px;
          border-bottom: 1px solid $grey_border;
          cursor       : pointer;

          &:hover {
            background-color: $main_light;
          }
        }
      }

      .gene_list_add_container {
        padding: 20px;

        .gene_list_add {
          width               : 200px;
          height              : 40px;
          @include font($size : 16px, $weight: 700);
        }
      }
    }

    .gene_list_info {
      background-color: $background_page;
      display         : flex;
      flex-direction  : column;
      flex-shrink     : 1;

      .gene_list_info_search_container {
        padding: 20px 20px 5px 20px;
      }

      .gene_list_info_action_container {
        padding: 5px 20px 20px 20px;

        .gene_list_info_actions {
          border-radius   : 5px;
          height          : 40px;
          background-color: $main_light;
          display         : flex;
          align-items     : center;
          justify-content : space-around;

          .gene_list_action_icon {
            cursor: pointer;
          }
        }
      }

      .gene_names_container {
        padding   : 0 20px 20px;
        overflow-y: scroll;
        @include scrollbar;
        display       : flex;
        flex-direction: column;
        gap           : 3px;

        .gene_name {
          @include font();
          background-color: $white;
          padding         : 8px 20px;
          border-radius   : 5px;
        }
      }
    }
  }
}
