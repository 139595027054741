@import 'scss/_variables';

.edit_gene_list_modal_container {
  @include modal;
  padding-bottom: 20px;

  .edit_gene_list_modal_title_container {
    padding      : 13px 0 13px 20px;
    border-bottom: 1px solid $grey_border;
  }

  .edit_gene_list_modal_content {
    padding       : 20px;
    display       : flex;
    flex-direction: column;
    gap           : 10px;
  }

  .edit_gene_list_modal_button_container {
    display        : flex;
    align-items    : center;
    justify-content: center;
    gap            : 10px;

    .edit_gene_list_modal_cancel {
      width              : 135px;
      @include font($size: 16px, $weight: 700);
    }

    .edit_gene_list_modal_save {
      width              : 135px;
      color              : $white;
      @include font($size: 16px, $weight: 700);
      box-shadow         : none;
    }
  }
}
