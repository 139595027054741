@import 'scss/_variables';

.file_table_container.MuiTableContainer-root {
  box-shadow: none;
  padding   : 20px;

  .file_table {

    th.MuiTableCell-root.MuiTableCell-head,
    th.MuiTableCell-root.MuiTableCell-body,
    td.MuiTableCell-root.MuiTableCell-body {
      @include font;
      border: 1px solid $grey_border;
    }

    .file_table_query_cell {
      max-width: 400px;

      .file_table_query_container {
        display        : flex;
        align-items    : center;
        justify-content: space-between;

        .file_table_formatted_query {
          overflow     : hidden;
          white-space  : nowrap;
          text-overflow: ellipsis;

          .file_table_query_dataset {
            font-weight: 700;

          }
        }
      }

      .file_query_first_level {
        list-style  : disc;
        padding-left: 20px;
      }

      .file_query_second_level {
        list-style  : circle;
        padding-left: 40px;
      }
    }


    .file_table_status_success {
      padding      : 5px 10px;
      border-radius: 5px;
      border       : 1px solid $main;
      color        : $main;
    }

    .file_table_status_pending {
      padding      : 5px 10px;
      border-radius: 5px;
      border       : 1px solid $grey_light;
      color        : $grey_light;
    }

    .file_table_status_error {
      padding      : 5px 10px;
      border-radius: 5px;
      border       : 1px solid $error;
      color        : $error;
    }

    .file_table_link {
      height: 24px;
    }
  }
}

.file_table_tooltip_container {
  .file_table_tooltip_wrapper {
    @include font;
    padding: 20px;
  }
}

.file_table_tooltip_outer_list {
  .file_table_tooltip_inner_list {
    list-style  : disc;
    padding-left: 15px;
  }
}
