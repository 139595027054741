.volcano_view_container {
  .volcano_plot_wrapper {
    width  : 100%;
    display: flex;

    &:not(:first-of-type) {
      margin-top: 15px;
    }
  }
}
