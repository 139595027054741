@import 'scss/_variables';

.four_way_table_container.MuiPaper-root {
  box-shadow: none;
  padding   : 7px 16px 0px;

  .four_way_table {
    border-top: 1px solid $grey_border;

    th.MuiTableCell-root.MuiTableCell-head,
    th.MuiTableCell-root.MuiTableCell-body,
    td.MuiTableCell-root.MuiTableCell-body {
      border: 1px solid $grey_border;
      @include font()
    }

    th.MuiTableCell-root.MuiTableCell-body,
    .MuiTableCell-root.MuiTableCell-body.gene_name_cell.gene_name_cell {
      @include font($weight: 700);
    }
  }
}

.four_way_table_actions {
  display        : flex;
  align-items    : center;
  justify-content: space-between;
  padding-left   : 15px;
}
