@import 'scss/_variables';

.collapse_container.MuiAccordion-root {
  box-shadow: none;

  &::before {
    display: none;
  }

  .MuiAccordionSummary-root {
    @include font($weight: 700);
    border               : 1px solid $main;
    border-radius        : 10px;

    .collapse_expanded_icon,
    .collapse_collapsed_icon {
      color: $main;
    }
  }

  .MuiAccordionDetails-root {
    &>*:not(:last-child) {
      margin-bottom: 10px;
    }
  }
}
