@import 'scss/_variables';

.field {
  width: 100%;

  .MuiFormLabel-root.MuiInputLabel-root {
    color: $text_light;

    &.Mui-error {
      color: $error;
    }

    &.Mui-focused {
      color: $text_light;

    }
  }

  .MuiInputBase-root.MuiFilledInput-root {
    @include font();
    color           : $text_dark;
    box-sizing      : border-box;
    border-radius   : 5px;
    background-color: $background_page;

    &.Mui-error {
      border: 1px solid $error;
    }

    &::before {
      border-bottom: none;
    }

    &::after {
      display: none;
    }

    &:hover {
      background-color: $background_page;

      &::before {
        border-bottom: none;
      }

      &:not(.Mui-disabled)::before {
        border-bottom: none;
      }
    }

    &:focus {
      background-color: $background_page;
    }
  }

  &.grey_background {
    .MuiInputBase-root.MuiFilledInput-root {
      background-color: $grey_border;
    }
  }
}
