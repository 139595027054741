@import 'scss/_variables';

.upload_dataset_modal_container {
  @include modal;

  &:focus-within {
    outline: none;
  }

  .upload_dataset_modal_title_container {
    padding      : 13px 0 13px 20px;
    border-bottom: 1px solid $grey_border;
  }

  .upload_dataset_modal_content {
    padding       : 20px;
    display       : flex;
    flex-direction: column;
    gap           : 10px;

    .upload_dataset_modal_check,
    .upload_dataset_modal_checked {
      @include font($weight: 700);
      color                : $white;
      @include font($size  : 16px, $weight: 700);
      box-shadow           : none;
    }

    .upload_dataset_modal_checked {
      background-color: $main;
    }

    .upload_dataset_modal_collapse_container {
      display           : flex;
      align-items       : center;
      justify-content   : space-around;
      // flex-direction : column;
      flex-wrap         : wrap;
      gap               : 5px;

      .upload_dataset_modal_dropdown {
        width: 48%;
      }
    }

    .upload_dataset_modal_button_container {
      display        : flex;
      align-items    : center;
      justify-content: center;
      gap            : 10px;

      .upload_dataset_modal_cancel {
        width               : 135px;
        @include font($size : 16px, $weight: 700);
      }

      .upload_dataset_modal_save {
        width              : 135px;
        color              : $white;
        @include font($size: 16px, $weight: 700);
        box-shadow         : none;
      }
    }
  }
}
