@import 'scss/_variables';

.categories_container {
  display       : flex;
  flex-direction: column;
  gap           : 10px;
  margin-top    : 20px;

  .category {
    display         : flex;
    align-items     : center;
    justify-content : space-evenly;
    gap             : 2px;
    padding         : 12px 0;
    flex            : 1;
    position        : relative;
    cursor          : pointer;
    color           : $main;
    background-color: $white;
    border          : 1px solid $main;
    border-radius   : 5px;
    @include font();

    &:hover {
      color       : $main_dark;
      border-color: $main_dark;
    }
  }
}
