@import 'scss/_variables';

.target_gene_table_section_container {
  display       : flex;
  flex-direction: column;
  gap           : 20px;

  .target_gene_category {
    padding         : 10px;
    background-color: $white;

    .target_gene_source_container {
      display       : flex;
      flex-direction: column;
      gap           : 10px;


      .target_gene_description_container {
        @include font();

        .target_gene_description {
          margin-bottom: 10px;

          .target_gene_link {
            text-decoration: none;
            color          : $main;

            &:hover {
              color: $main_dark;
            }
          }
        }
      }
    }

  }

}
