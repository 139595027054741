@import 'scss/_variables';

.dataset_table_container.MuiTableContainer-root {
  padding   : 20px;
  box-shadow: none;

  .dataset_table {

    th.MuiTableCell-root.MuiTableCell-head,
    th.MuiTableCell-root.MuiTableCell-body,
    td.MuiTableCell-root.MuiTableCell-body {
      @include font($size: 12px);
    }

    th.MuiTableCell-root.MuiTableCell-head {
      color: $grey_light;
    }

    .dataset_table_row.MuiTableRow-root {

      &:hover {
        background-color: $main_light;

        td.dataset_table_action_cell {

          .dataset_table_edit,
          .dataset_table_delete {
            opacity   : 1;
            visibility: visible;
          }
        }
      }

      td.MuiTableCell-root.MuiTableCell-body:first-child {
        font-weight: 700;
      }

      td.dataset_table_action_cell {
        vertical-align: middle;
        text-align    : center;

        .dataset_table_edit,
        .dataset_table_delete {
          @include font($size: 12px);
          opacity            : 0;
          visibility         : hidden;
        }
      }

      .dataset_table_status_success {
        padding      : 5px 10px;
        border-radius: 5px;
        border       : 1px solid $main;
        color        : $main;
      }

      .dataset_table_status_pending {
        padding      : 5px 10px;
        border-radius: 5px;
        border       : 1px solid $grey_light;
        color        : $grey_light;
      }

      .dataset_table_status_error {
        padding      : 5px 10px;
        border-radius: 5px;
        border       : 1px solid $error;
        color        : $error;
      }
    }
  }
}
