@import 'scss/_variables';

.four_way_sidebar_container {
  @include sidebar;

  .four_way_sidebar_title {
    display      : inline-block;
    margin-bottom: 20px;
  }

}
