@import 'scss/_variables';

.gene_select_tabs_container {
  display       : flex;
  flex-direction: column;
  gap           : 10px;

  .gene_select_tabs {
    display        : flex;
    align-items    : center;
    justify-content: space-between;
    border-radius  : 5px;
    overflow       : hidden;

    .gene_select_tab {
      display         : flex;
      align-items     : center;
      justify-content : space-evenly;
      gap             : 2px;
      background-color: $background_page;
      padding         : 12px 0;
      flex            : 1;
      position        : relative;
      cursor          : pointer;
      @include font();

      &:hover {
        color: $main;

        &:before {
          content         : '';
          width           : 100%;
          height          : 3px;
          background-color: $main;
          position        : absolute;
          bottom          : 0;
          left            : 0;
          right           : 0;
        }
      }

      &.active {
        &:before {
          content         : '';
          width           : 100%;
          height          : 3px;
          background-color: $main;
          position        : absolute;
          bottom          : 0;
          left            : 0;
          right           : 0;
        }
      }
    }
  }
}
