@import 'scss/_variables';

@mixin activeMark {
  &:before {
    content         : '';
    height          : 100%;
    width           : 3px;
    background-color: $main;
    position        : absolute;
    top             : 0;
    right           : 0;
    bottom          : 0;
  }
}

.genes_sidebar_container {
  @include sidebar;

  .gene_sidebar_controls_container {
    display       : flex;
    flex-direction: column;
    gap           : 10px;

    .gene_tabs_container {
      display        : flex;
      align-items    : center;
      justify-content: space-between;
      border-radius  : 5px;
      overflow       : hidden;

      .gene_tab {
        display         : flex;
        align-items     : center;
        justify-content : space-evenly;
        gap             : 2px;
        background-color: $background_page;
        padding         : 12px 0;
        flex            : 1;
        position        : relative;
        cursor          : pointer;
        @include font();

        &:hover {
          &:before {
            content         : '';
            width           : 100%;
            height          : 3px;
            background-color: $main;
            position        : absolute;
            bottom          : 0;
            left            : 0;
            right           : 0;
          }

          .gene_tab_count {
            color: $main;
          }
        }

        &.active {
          &:before {
            content         : '';
            width           : 100%;
            height          : 3px;
            background-color: $main;
            position        : absolute;
            bottom          : 0;
            left            : 0;
            right           : 0;
          }
        }

        .gene_tab_text {
          color: $text_dark;
        }
      }
    }

    .genes_sidebar_datasets_container {
      height: 100%;

      .genes_sidebar_datatype_header {
        @include font($size: 16px);

        &:not(:first-of-type) {
          margin-top: 30px;

        }
      }

      .genes_sidebar_dataset {
        @include font();
        height      : 50px;
        padding-left: 20px;
        display     : flex;
        align-items : center;
        cursor      : pointer;
        position    : relative;

        &:not(:last-child) {
          border-bottom: 1px solid $grey_border;

        }

        &:hover {
          background-color: $main_light;
          @include activeMark;
        }

        &.active {
          background-color: $main_light;

        }

        .gene_sidebar_dataset_label {
          text-overflow: ellipsis;
          overflow     : hidden;
          white-space  : nowrap;
        }
      }
    }
  }
}
