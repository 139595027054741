@import 'scss/_variables';

.volcano_params_container {
  background-color: $white;
  display         : flex;
  align-items     : center;
  justify-content : space-between;
  padding         : 15px;

  .volcano_param_input {
    width       : 250px;
    margin-right: 10px;
  }
}
