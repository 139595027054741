@import 'scss/_variables';

.gene_info_container {
  display       : flex;
  flex-direction: column;
  gap           : 20px;

  .gene_header_container {
    display         : flex;
    align-items     : center;
    justify-content : space-between;
    padding         : 13px 20px;
    border-radius   : 10px;
    background-color: $white;

    .gene_header_info {
      display    : flex;
      align-items: baseline;
      gap        : 10px;
    }

    .gene_header_button {
      @include font($weight: 700);
    }
  }

  .gene_links_container {
    display: flex;
    gap    : 20px;

    .gene_links_section {
      @include panel;
      padding       : 20px;
      flex          : 1;
      display       : flex;
      flex-direction: column;
      gap           : 10px;

      .gene_links_wrapper {
        display       : flex;
        flex-direction: column;
        gap           : 5px;

        .gene_link_source {
          display    : flex;
          align-items: center;
          @include font;

          .gene_link_link {
            font-weight    : 700;
            color          : $main;
            padding-left   : 20px;
            text-decoration: none;
          }
        }
      }
    }
  }

  .gene_synonyms_container {
    @include panel;
    padding       : 20px;
    display       : flex;
    flex-direction: column;
    gap           : 10px;

    .gene_synonyms {
      display: flex;
      gap    : 20px;

      .gene_species_synonyms {
        @include font;
        display    : flex;
        align-items: center;
        gap        : 5px;
      }
    }
  }

  .gene_description_container {
    @include panel;
    @include font;
    padding: 20px;
  }
}
