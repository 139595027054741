@import 'scss/_variables';

.group_search_panel_container {
  @include panel;
  display        : flex;
  align-items    : center;
  justify-content: space-between;

  .group_search_panel_info {
    display    : flex;
    align-items: center;
    gap        : 10px;

    .group_search_panel_quantity {
      @include font($weight : 700, $size: 20px, $line_height: 40px);
      color                 : $grey_light;
      display               : inline-block;
    }
  }

  .group_search_panel_actions {
    display    : flex;
    align-items: center;
    gap        : 10px;

    .group_search_panel_button {
      @include font($weight: 700);
      box-shadow           : none;
      color                : $white;
      min-width            : 150px;
      height               : 50px;
      flex-shrink          : 0;
    }
  }
}
