@import 'scss/_variables';

.group_table_container.MuiTableContainer-root {
  padding   : 20px;
  box-shadow: none;

  .group_table {

    th.MuiTableCell-root.MuiTableCell-head,
    th.MuiTableCell-root.MuiTableCell-body,
    td.MuiTableCell-root.MuiTableCell-body {
      @include font;
    }

    th.MuiTableCell-root.MuiTableCell-head {
      color: $grey_light;
    }

    .group_table_row.MuiTableRow-root {

      &:hover {
        background-color: $main_light;

        td.group_table_action_cell {

          .group_table_edit,
          .group_table_delete {
            opacity   : 1;
            visibility: visible;
          }
        }
      }

      td.MuiTableCell-root.MuiTableCell-body:nth-child(1) {
        width: 40px;
      }

      td.MuiTableCell-root.MuiTableCell-body:nth-child(2) {
        font-weight: 700;
      }

      td.group_table_collapsible_cell {
        .group_table_details_container {
          background-color: $grey_border;
          padding         : 20px;
          display         : flex;
          justify-content : space-around;

          .group_table_details_column {
            display       : flex;
            flex-direction: column;
            list-style    : disc;
          }
        }
      }

      td.group_table_action_cell {
        vertical-align: middle;

        .group_table_edit,
        .group_table_delete {
          @include font;
          opacity   : 0;
          visibility: hidden;
        }
      }

      .group_table_collapsible_cell {
        padding      : 0;
        border-bottom: none;
      }
    }
  }
}
