@import 'scss/_variables';

.multigene_sidebar_container {
  @include sidebar();

  .multigene_sidebar_title {
    margin-bottom: 20px;
    display      : inline-block;
  }

  .multigene_sidebar_subtitle {
    display: block;
  }

  .multigene_sidebar_genes_field {
    label {
      font-size: 14px;
    }
  }

  .multigene_sidebar_add {
    width: 100%;
    @include font();
  }

  .multigene_sidebar_generate {
    width: 100%;
    @include font();
    color     : $white;
    box-shadow: none;
  }

  .multigene_sidebar_reset {
    width: 100%;
    @include font;
  }
}
