@import 'scss/_variables';

.experiments_sidebar_container {
  @include sidebar;
  flex-shrink: 0;

  .experiments_sidebar_title_container {
    display        : flex;
    justify-content: space-between;
    align-items    : center;
    margin-bottom  : 20px;

    .experiments_sidebar_title {
      display: inline-block;
    }
  }

  .experiments_sidebar_link_container {
    padding: 0 10px;

    .experiments_sidebar_link {
      display              : flex;
      align-items          : center;
      justify-content      : space-between;
      gap                  : 5px;
      text-decoration      : none;
      color                : $text_dark;
      cursor               : pointer;
      @include font($weight: 600);
    }
  }

  .experiments_sidebar_actions {
    display        : flex;
    align-items    : center;
    justify-content: center;

    .experiments_sidebar_favorite {
      @include font($weight: 700);
    }

    .experiments_sidebar_reset {
      @include font($weight: 700);
    }
  }
}
