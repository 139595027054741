// COLORS

$background       : #262E3D;
$background_active: #222A38;
$background_page  : #F4F8FB;
$error            : #D85151;
$grey             : #5D6470;
$grey_border      : #E1E1E1;
$grey_dark        : #313947;
$grey_light       : #94999e;
$main             : #26C2B5;
$main_dark        : #235158;
$main_light       : #26C2B51A;
$text_dark        : #303030;
$text_light       : #94999e;
$white            : #FFFFFF;

// FONTS
$font_family: 'Manrope',
sans-serif;

//MIXINS
@mixin font($style: normal, $weight: 500, $size: 14px, $line_height: 24px, $text_transform: none) {
  font-family   : $font_family;
  font-style    : $style;
  font-weight   : $weight;
  font-size     : $size;
  line-height   : $line_height;
  text-transform: $text_transform;
}

@mixin panel($padding: 20px) {
  background-color: $white;
  border-radius   : 10px;
  padding         : $padding;
}

@mixin scrollbar() {
  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background   : $grey_border;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background   : $main;
    border-radius: 5px;
  }
}

@mixin sidebar($panel_padding: 20px) {
  @include panel($padding: $panel_padding);
  max-width              : 320px;
  width                  : 100%;
  margin-right           : 35px;
  height                 : 100%;

  &>*:not(:last-child) {
    margin-bottom: 10px;
  }
}

@mixin modal($width: 480px) {
  @include panel(0);
  width    : $width;
  position : absolute;
  top      : 50%;
  left     : 50%;
  transform: translate(-50%, -50%);
}
