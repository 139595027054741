@import 'scss/_variables';

.experiment_favorites_modal {
  @include modal;

  .experiment_favorites_modal_title_container {
    padding      : 13px 0 13px 20px;
    border-bottom: 1px solid $grey_border;
  }

  .experiment_favorites_modal_content {
    padding       : 20px;
    display       : flex;
    flex-direction: column;
    gap           : 10px;
  }

  .experiment_favorites_modal_button_container {
    display        : flex;
    align-items    : center;
    justify-content: center;
    gap            : 10px;
    padding        : 0 0 10px;

    .experiment_favorites_modal_cancel {
      width              : 135px;
      @include font($size: 16px, $weight: 700);
    }

    .experiment_favorites_modal_save {
      width              : 135px;
      color              : $white;
      @include font($size: 16px, $weight: 700);
      box-shadow         : none;
    }
  }
}
