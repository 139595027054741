@import 'scss/_variables';

.top_menu_container {
  position       : fixed;
  top            : 0;
  right          : 0;
  left           : 40px;
  height         : 50px;
  background     : $white;
  display        : flex;
  align-items    : center;
  justify-content: space-between;
  padding-left   : 20px;
  box-shadow     : 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
  z-index        : 10;
}
