@import 'scss/_variables';

.confirmation_modal_container {
  @include modal;
  padding: 20px;

  .confirmation_modal_title {
    @include font($size: 20px, $weight: 700);
    margin-bottom      : 10px;
  }

  .confirmation_modal_description {
    @include font($size: 16px);
    margin-bottom      : 15px;
  }

  .confirmation_modal_button_container {
    display        : flex;
    align-items    : center;
    justify-content: space-between;
    gap            : 10px;

    .confirmation_modal_cancel {
      width               : 135px;
      @include font($size : 16px, $weight: 700);
    }

    .confirmation_modal_save {
      width              : 135px;
      color              : $white;
      @include font($size: 16px, $weight: 700);
      box-shadow         : none;
    }
  }
}
